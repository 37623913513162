import {useEffect, useState} from "react";
import {Blurhash} from "react-blurhash";

const BlurImage = ({hash, src, x, y}) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    useEffect(() => {
        const img = new Image()
        img.onload = () => {
            setImageLoaded(true)
        }
        img.loading = 'lazy'
        img.src = src
    }, [src])

    return <div>
        <Blurhash
            hash={hash}
            resolutionX={32}
            resolutionY={32}
            width={x}
            height={y}
            style={{
                width: '100%',
                height: '600px',
                borderRadius: 4,
                display: !imageLoaded ? 'inline': 'none'
            }}
        />
        <img src={src} alt="" style={{
            display: imageLoaded ? 'inline': 'none',
            borderRadius: 4,
            width: '100%',
        }}/>
    </div>
}

export default BlurImage