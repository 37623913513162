import ResponsiveAppBar from "../components/AppBar";
import {Grid, Stack, Typography, useTheme} from "@mui/material";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";


const Faq = () => {
    const [loading, setLoading] = useState(true)
    const [faqs, setFaqs] = useState([])
    const {getData} = useCallDataApi('faqs')
    const theme = useTheme()
    document.title = 'Gyakori kérdések'

    useEffect(() => {
        getData()
            .then(a => setFaqs(a))
            .finally(() => setLoading(false))
    }, [])

    return <>
        <ResponsiveAppBar/>
        <div className="main" style={{minHeight: '70vh'}}>
            <Typography variant='headline1'>Gyakori kérdések</Typography>
            {faqs?.map((q, i) => <Grid marginTop={3} item xs={12} md={6} xl={6} key={`us_${i}`}>
                <div className='apartment-card' style={{padding: '20px', background: theme.palette.primary[20]}}>
                    <Stack spacing={2} sx={{width: '100%'}}>
                        <Typography variant='headline3'>{q?.title}</Typography>
                        <Typography variant='body2'>{q?.description}</Typography>
                    </Stack>
                </div>
            </Grid>)}
        </div>
        <Footer/>
        <Loading isLoading={loading} />
    </>
}

export default Faq