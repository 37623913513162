const typography = {
    headline1: {
        fontFamily: "Amatic SC",
        color: '#523A28',
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "56px",
        lineHeight: "61px",
        '@media (max-width: 780px)': {
            fontSize: "40px"
        }
    },
    headline2: {
        fontFamily: "Amatic SC",
        fontStyle: "normal",
        color: '#523A28 !important',
        fontWeight: 800,
        fontSize: "40px",
        lineHeight: "44px",
        '@media (max-width: 780px)': {
            fontSize: "38px"
        }
    },
    headline3: {
        fontFamily: "Amatic SC",
        fontStyle: "normal",
        color: '#523A28 !important',
        fontWeight: 800,
        fontSize: "38px",
        lineHeight: "41px",
        '@media (max-width: 780px)': {
            fontSize: "32px"
        }
    },
    headline4: {
        fontFamily: "Amatic SC",
        fontStyle: "normal",
        color: '#523A28',
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "37px",
        '@media (max-width: 780px)': {
            fontSize: "30px"
        }
    },
    headline5: {
        fontFamily: "Amatic SC",
        fontStyle: "normal",
        color: '#523A28 !important',
        fontWeight: 700,
        fontSize: "28px",
        lineHeight: "30px",
        '@media (max-width: 780px)': {
            fontSize: "22px"
        }
    },
    headline6: {
        fontFamily: "Amatic SC",
        fontStyle: "normal",
        color: '#523A28 !important',
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "26px",
        '@media (max-width: 780px)': {
            fontSize: "18px"
        }

    },
    body1: {
        fontFamily: 'Poiret One',
        fontStyle: 'normal',
        color: '#523A28 !important',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '30px',
        '@media (max-width: 780px)': {
            fontSize: "14px"
        }

    },
    overlineBody1: {
        fontFamily: 'Poiret One',
        fontStyle: 'normal',
        textDecoration: 'line-through',
        color: '#523A28 !important',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '16px',
        '@media (max-width: 780px)': {
            fontSize: "14px"
        }

    },
    body2: {
        fontFamily: 'Poiret One',
        fontStyle: 'normal',
        color: '#523A28 !important',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '18px',
        '@media (max-width: 780px)': {
            fontSize: "14px"
        }

    },
    subtitle1: {
        fontFamily: 'Amatic SC',
        fontStyle: 'normal',
        color: '#523A28 !important',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '25px',
        '@media (max-width: 780px)': {
            fontSize: "14px"
        }

    },
    subtitle2: {
        fontFamily: 'Amatic SC',
        fontStyle: 'normal',
        color: '#523A28 !important',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '20px',
        '@media (max-width: 780px)': {
            fontSize: "14px"
        }

    },
    caption: {
        fontFamily: 'Poiret One',
        fontStyle: 'normal',
        color: '#523A28 !important',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '13px',
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
        '@media (max-width: 780px)': {
            fontSize: "10px"
        }

    },
    button: {
        fontFamily: 'Poiret One',
        fontStyle: 'normal',
        color: '#523A28 !important',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        textTransform: 'lowercase',
        '@media (max-width: 780px)': {
            fontSize: "13px"
        }

    },
    buttonNormal: {
        fontFamily: 'Poiret One',
        fontStyle: 'normal',
        color: '#523A28 !important',
        fontWeight: 600,
        fontSize: '17px',
        lineHeight: '18px',
        '@media (max-width: 780px)': {
            fontSize: "13px"
        }

    },
    overline: {
        fontFamily: 'Poiret One',
        fontStyle: 'normal',
        color: '#523A28 !important',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '11px',
        textTransform: 'uppercase',
        letterSpacing: '0.015em',

    }
}

export default typography