import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import {useHistory} from "react-router-dom";
import belaWoodLogo from '../assets/BeLa_wood_logo_white.png'
import {Drawer, useTheme} from "@mui/material";
import {HashLink} from "react-router-hash-link";
import {useEffect, useState} from "react";

const pages = [
    {name: 'Rólunk', url: '/about-us'},
    {name: 'Galéria', url: '/gallery'},
    {name: 'Apartmanok', url: '/houses'},
    {name: 'Kapcsolat', url: '/#contact'},
    {name: 'Gyik', url: '/faq'}
];

function ResponsiveAppBar({fade}) {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [scrollHeight, setScrollHeight] = useState(0)
    const history = useHistory()
    const theme = useTheme()

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const toggleNavMenu = (event) => {
        setAnchorElNav(!Boolean(anchorElNav) ? event.currentTarget : null);
    };

    const menuIconStyle = {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '30px',
        color: 'white',
    }

    const scrollWithOffset = (el) => {
        setTimeout(() => {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -50;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }, 500)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("scroll", scrollListener)
    }, [])

    const scrollListener = (event) => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setScrollHeight(winScroll)
    }

    return (
        <><AppBar position="fixed" style={(fade && scrollHeight < 50) ? {backgroundColor: 'transparent', transition: 'background-color 0.5s ease'} : {backgroundColor: theme.palette.primary[400], transition: 'background-color 0.5s ease'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src={belaWoodLogo} alt="BelaWood logo" style={{cursor: 'pointer'}} width={100} onClick={() => history.push('/')}/>

                    <Box sx={{display: {xs: 'flex', md: 'none'}, marginLeft: 'auto', zIndex: '11'}}>
                        <div style={menuIconStyle}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={toggleNavMenu}
                                color="inherit"
                            >
                                {!anchorElNav ? <MenuIcon/> : <CloseIcon/>}
                            </IconButton>
                        </div>
                        <Drawer
                            anchor={'top'}
                            open={Boolean(anchorElNav)}
                            onClose={toggleNavMenu}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            BackdropProps={{invisible: true}}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    height: '80%',
                                    // marginTop: '95px'
                                },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: '10'
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                margin: 'auto',
                                gap: '48px'
                            }}>
                                {pages.map((page) => (
                                    <MenuItem key={page.name + 'mobile'} onClick={handleCloseNavMenu}>
                                        <HashLink to={page.url} scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none', color: theme.palette.primary[600]}}>
                                            <Typography textAlign="center" variant="subtitle2" style={{fontSize: '250%'}}>{page.name}</Typography>
                                        </HashLink>
                                    </MenuItem>
                                ))}
                            </div>

                        </Drawer>

                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, gap: 4}}>
                        {pages.map(p => <HashLink className='nav-button' key={`b_${p.name}`} to={p.url} scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="headline4" color='#fff'>{p.name}</Typography>
                        </HashLink>)}
                        {/*<Button onClick={() => window.scrollTo(0, 0)}>scroll top</Button>*/}
                    </Box>
                    <Button sx={{display: {xs: 'none', md: 'flex'}}} size='large' startIcon={<CalendarMonthRoundedIcon/>} variant='contained' onClick={() => history.push('/houses')} style={{background: '#fff', color: theme.palette.primary[600]}}>Árkalkuláció és foglalás</Button>
                    </Toolbar>
            </Container>
        </AppBar>
            {!fade && <Toolbar/>}
        </>
    );
}

export default ResponsiveAppBar;