import {useMemo} from "react";
import {useLocation} from "react-router-dom";

export function useAuthHeader() {
    return {
        headers: {
            "Content-Type": "application/json",
        },
        formDataHeaders: {
            "Content-Type": "multipart/form-data",
        },
    };
}

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}