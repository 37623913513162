import {IconButton, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import greenLogo from "../assets/BeLa_wood_logo_black.png"
import simple from "../assets/simplepay_bankcard_logos_top_02.png"
import szep from "../assets/otpszepkartya_transparent.png"
import {HashLink} from "react-router-hash-link";
import {useContext} from "react";
import ConfigContext from "../context/ConfigContext";


const DinoFooter = () => {
    const theme = useTheme()
    const {baseUrl} = useContext(ConfigContext)
    const pages = [
        {name: 'Kezdőlap', url: '/'},
        {name: 'Rólunk', url: '/about-us'},
        {name: 'Galéria', url: '/gallery'},
        {name: 'Vendégházunk', url: '/houses'},
        {name: 'Kapcsolat', url: '/#contact'},
        {name: 'Gyik', url: '/faq'}
    ];

    const scrollWithOffset = (el) => {
        setTimeout(() => {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -50;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }, 500)
    }

    const primaryColor = {color: theme.palette.primary[700]}

    return <div className="dino-footer" style={{background: theme.palette.primary[20]}}>
        <div className="dino-footer-content">
            <Stack spacing={8} direction={{xs: 'column', md: 'row', xl: 'row'}} justifyContent='space-between'>
                <Stack item xs={12} md={6} lg={3}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}} style={{marginBottom: '20px'}}>
                        <Typography variant="headline4">Kapcsolat</Typography>
                        <Stack spacing={1} direction='row'>
                            <LocationOnRoundedIcon style={primaryColor}/>
                            <Typography style={primaryColor} sx={{alignSelf: 'center'}} variant='buttonNormal'>3394 Egerszalók, Galamb utca 29</Typography>
                        </Stack>
                        <Stack spacing={1} direction='row'>
                            <LocalPhoneRoundedIcon style={primaryColor}/>
                            <Typography style={primaryColor} sx={{alignSelf: 'center'}} variant='buttonNormal'>+36 30 864 2606</Typography>
                        </Stack>
                        <Stack spacing={1} direction='row'>
                            <EmailRoundedIcon style={primaryColor}/>
                            <Typography style={primaryColor} sx={{alignSelf: 'center'}} variant='buttonNormal'>belawoodinfo@gmail.com</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <IconButton style={primaryColor} size='large' onClick={() => window.open('https://www.facebook.com/tinyhouse.belawood', '_blank')}><FacebookOutlinedIcon/></IconButton>
                        <IconButton style={primaryColor} size='large' onClick={() => window.open('https://www.instagram.com/belawoodtinyhouse', '_blank')}><InstagramIcon/></IconButton>
                    </Stack>
                </Stack>
                <Stack item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="headline4">Szekciók</Typography>
                        {pages.map(p => <HashLink key={p.name} to={p.url} scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="buttonNormal" style={primaryColor}>{p.name}</Typography>
                        </HashLink>)}

                    </Stack>
                </Stack>
                <Stack item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="headline4">Dokumentumok</Typography>
                        <Typography onClick={() => window.location.href = `${baseUrl}/media/aszf.pdf`} style={{...primaryColor, cursor: 'pointer'}} variant='buttonNormal'>ÁSZF</Typography>
                        <Typography onClick={() => window.location.href = `${baseUrl}/media/adat.pdf`} style={{...primaryColor, cursor: 'pointer'}} variant='buttonNormal'>Adatkezelési szabályzat</Typography>

                    </Stack>
                </Stack>
                <Stack item xs={12} md={6} lg={3}>
                    <Stack spacing={2}  alignItems='center'>
                        <img src={greenLogo} alt="Belawood logo" width="200px"/>
                        <a target='_blank' href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf">
                            <img src={simple} alt="SimplePay logo" width="200px"/>
                        </a>
                        <img src={szep} alt="Szépkártya logo" width="200px"/>
                    </Stack>
                </Stack>
            </Stack>

        </div>
    </div>

}

export default DinoFooter