import {createContext, useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import Loading from "../components/Loading";

const ApartmentContext = createContext();

export default ApartmentContext;

export const ApartmentProvider = ({ children }) => {
    const {getData: fetchApartments} = useCallDataApi('apartments')
    const [apartments, setApartments] = useState([])
    const [apartmentsLoaded, setApartmentsLoaded] = useState(false)

    const getApartment = (id) => apartments.find(a => a?.id === id)

    useEffect(() => {
        fetchApartments().then(a => setApartments(a)).finally(() => setApartmentsLoaded(true))
    }, [])

    const contextData = {
        apartments,
        getApartment
    }

    return <ApartmentContext.Provider value={contextData}>{apartmentsLoaded ? children : <Loading isLoading={!apartmentsLoaded}/>}</ApartmentContext.Provider>
}