import ResponsiveAppBar from "../components/AppBar";
import Footer from "../components/Footer";
import {Stack, Typography} from "@mui/material";
import {useQuery} from "../hooks/hooks";
import {fromBase64} from "js-base64";

const PaymentResult = () => {
    let query = useQuery()
    const simpleResponse = JSON.parse(fromBase64(query.get('r')))

    const success = <>
        <Stack spacing={2}>
            <Typography variant='headline2' color='#38474D'>Sikeres tranzakció!</Typography>
            <Typography variant='body2' color='#38474D'>Tranzakció azonosító: {simpleResponse?.t}</Typography>
            <Typography variant='body2' color='#38474D'>Köszönjük foglalásod, sikeresen megterheltük a kártyádat, így véglegessé vált a foglalásod.További teendőd ezzel kapcsolatban nincsen.
                A visszaigazolást és a számlát elküldtük a megadott email címre.
                Várjuk a találkozást!
            </Typography>
        </Stack>
    </>

    const fail = <>
        <Stack spacing={2}>
            <Typography variant='subtitle1' color='#38474D'>Sikertelen tranzakció!</Typography>
            <Typography variant='body2' color='#38474D'>Tranzakció azonosító: {simpleResponse?.t}</Typography>
            <Typography variant='body2' color='#38474D'>Kérjük, ellenőrizd a tranzakció során megadott adatok
                helyességét. Amennyiben minden adatot helyesen adtál meg, a visszautasítás okának kivizsgálása érdekében
                kérjük, lépj kapcsolatba kártyakibocsátó bankjával.</Typography>
        </Stack>
    </>

    const cancel = <>
        <Stack spacing={2}>
            <Typography variant='subtitle1' color='#38474D'>Megszakított fizetés!</Typography>
            <Typography variant='body2' color='#38474D'>Tranzakció azonosító: {simpleResponse?.t}</Typography>
            <Typography variant='body2' color='#38474D'>A tranzakciót megszakítottad, kérjük próbáld meg újra.</Typography>
        </Stack>
    </>

    const timeout = <>
        <Stack spacing={2}>
            <Typography variant='subtitle1' color='#38474D'>Időtúllépés!</Typography>
            <Typography variant='body2' color='#38474D'>Tranzakció azonosító: {simpleResponse?.t}</Typography>
            <Typography variant='body2' color='#38474D'>A tranzakció időtúllépés miatt nem teljesült, kérjük próbáld meg újra.</Typography>
        </Stack>
    </>

    const resultMap = {
        SUCCESS: success,
        FAIL: fail,
        CANCEL: cancel,
        TIMEOUT: timeout
    }

    return <>
        <ResponsiveAppBar />
        <div className="main" style={{minHeight: '60vh'}}>
            {resultMap[simpleResponse?.e]}
        </div>
        <Footer/>
    </>
}

export default PaymentResult