import ResponsiveAppBar from "../components/AppBar";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import Footer from "../components/Footer";
import Loading from "../components/Loading";

const AboutUs = () => {
    const theme = useTheme()
    const [us, setUs] = useState([])
    const [loading, setLoading] = useState(true)
    const {getData: fetchUs} = useCallDataApi('about-us')
    useEffect(() => {
        fetchUs().then(u => setUs(u)).finally(() => setLoading(false))
    }, [])

    document.title = 'Rólunk'

    return <>
        <ResponsiveAppBar/>
        <div className="main" style={{minHeight: '70vh'}}>
            <Typography variant='headline1'>Rólunk</Typography>
            <Typography variant='body1' marginBottom={1} align='justify'>Üdvözlünk az oldalunkon! Örülünk, hogy ittvagy!
            </Typography>
            <Typography variant='body1' marginBottom={1} align='justify'>
                Ismerd meg a mi történetünket…
            </Typography>
            <Typography variant='body1' marginBottom={1} align='justify'>
                Volt egy álmunk, melyet a BeLaWood testesít most meg. Két kis kuckó, amely teljes egészében a mi elképzeléseink alapján készült. Benne van szívünk, lelkünk.
                Törekedtünk arra, hogy ebben a 2 kis kuckóban minden megtalálható legyen ami a teljes kényelemhez, pihenéshez kell. Legalábbis szerintünk…de gyere és próbáld ki te is!
            </Typography>
            <Typography variant='body1' marginBottom={1} align='justify'>
                A 2ME és a 4YOU Apartmanok Eger közelében Egerszalókon várja az ide látogatókat. Lenyűgöző kilátás az erdőre, melyet akár a jakuzziból is megcsodálhatunk.
            </Typography>
            <Typography variant='body1' marginBottom={1} align='justify'>
                Két kis kuckó tele csodával!
            </Typography>
            <Typography variant='body1' marginBottom={1} align='justify'>
                Nyugalom, csend, természet és béke…
            </Typography>
            <Typography variant='body1' marginBottom={4} align='justify'>
                …a két kis kuckónk a domboldal éke!
            </Typography>

            <Grid container spacing={3}>

                {us?.map((u, i) => <Grid item xs={12} md={6} xl={6} key={`us_${i}`}>
                    <div className='apartment-card' style={{padding: '20px', background: theme.palette.primary[20]}}>
                        <Stack spacing={2} sx={{width: '100%'}}>
                            <Typography variant='headline3'>{u?.heading}</Typography>
                            <Box className='about-us-img' style={{background: `url("${u?.image}")`}}/>
                            <Typography variant='body2'>{u?.text}</Typography>
                        </Stack>
                    </div>
                </Grid>)}

            </Grid>
        </div>
        <Footer/>
        <Loading isLoading={loading} />
    </>
}

export default AboutUs