import ResponsiveAppBar from "../components/AppBar"

import * as React from 'react';
import Masonry from '@mui/lab/Masonry';
import {useContext, useState} from "react";
import useCallDataApi from "../hooks/data";
import {Blurhash} from "react-blurhash";
import BlurImage from "../components/BlurImage";
import Footer from "../components/Footer";
import {Stack, Typography} from "@mui/material";
import Loading from "../components/Loading";
import ApartmentContext from "../context/ApartmentContext";


const Gallery = () => {
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(true)
    const {getData: fetchImages} = useCallDataApi('image-gallery')
    const {apartments} = useContext(ApartmentContext)

    document.title = 'Galéria'

    useState(() => {
        fetchImages().then(i => setImages(i)).finally(() => setLoading(false))
    }, [])

    return <>
        <ResponsiveAppBar/>

        <div className="main">
            <Typography variant='headline1'>Galéria</Typography>

            {apartments.map(a => <Stack spacing={1} key={a?.id}>
                <Typography variant='headline2'>{a?.name}</Typography>
                <Masonry columns={3} spacing={2} sx={{display: {xs: 'none', md: 'none', lg: 'flex'}}}>
                    {images?.filter(i => i?.apartment === a?.id)?.map((item, index) => (
                        <div key={index}>
                            <img src={item.image} style={{maxWidth: '100%'}}/>
                        </div>
                    ))}
                </Masonry>
                <Stack spacing={1} sx={{display: {xs: 'flex', md: 'flex', lg: 'none', xl: 'none'}}}>
                    {images?.filter(i => i?.apartment === a?.id)?.map((item, index) => (
                        <div key={index}>
                            <img src={item.image} style={{maxWidth: '100%'}}/>
                        </div>
                    ))}
                </Stack>
            </Stack>)}
        </div>
        <Loading isLoading={loading}/>
        <Footer/>
    </>
}

export default Gallery