import ResponsiveAppBar from "../components/AppBar";
import Footer from "../components/Footer";
import {Typography} from "@mui/material";

const BeautifulCard = () => {
    return <>
        <ResponsiveAppBar/>
        <div className="main" style={{minHeight: '60vh'}}>
            <Typography variant='headline1'>Szép kártyás fizetés</Typography>
            <Typography variant='body1' marginBottom={4}>Az apartmant sikeresen lefoglaltuk számodra, viszont ne feledd, hogy 3 nap áll rendelkezésedre a foglalás véglegesítésére azáltal, hogy az OTP SZÉP-kártya felületén kifizeted a teljes összeget részünkre.
                A folyamatról emailben tájékoztatunk, de az alábbi <a target='_blank' href="https://magan.szepkartya.otpportalok.hu/elfogadohelyek/adatlap/368073/BeLaWood%20/">linkre</a> kattintva pár kattintással véglegesítheted a fizetést.
            </Typography>
        </div>
        <Footer/>
    </>
}

export default BeautifulCard