import ResponsiveAppBar from "../components/AppBar";
import Footer from "../components/Footer";
import {Typography} from "@mui/material";
import sad from '../assets/Sad Man Crying Out Loud Meme.gif'

const NotFound = () => {
    return <>
        <ResponsiveAppBar/>
        <div className="main">
            <Typography variant='headline1'>Az oldal nem található</Typography>
            <Typography variant='body1' marginBottom={4}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur corporis deserunt dolorem illum magnam maxime officiis quia voluptas voluptatem voluptates. Dolor eum facere incidunt nisi quisquam quos repellendus similique ullam?</Typography>
            <img src={sad} alt="not found" style={{width: '30%', margin: '0 auto'}}/>
        </div>
        <Footer/>
    </>
}

export default NotFound