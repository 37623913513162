import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ConfigProvider} from "./context/ConfigContext";
import {ThemeProvider} from "@mui/material";
import Home from "./pages/Home";
import belaWoodTheme from "./components/theme/BelaWoodTheme";
import Gallery from "./pages/Gallery";
import AboutUs from "./pages/AboutUs";
import Houses from "./pages/Houses";
import NotFound from "./pages/NotFound";
import ReserveForHouse from "./pages/ReserveForHouse";
import PaymentResult from "./pages/PaymentResult";
import BeautifulCard from "./pages/BeautifulCard";
import Transfer from "./pages/Transfer";
import {ApartmentProvider} from "./context/ApartmentContext";
import Faq from "./pages/Faq";

function App() {
    document.title = 'BeLaWood'
    return <ThemeProvider theme={belaWoodTheme}>
        <ConfigProvider>
            <ApartmentProvider>
                <Router>
                    <Switch>
                        <Route path="/" component={Home} exact/>
                        <Route path="/gallery" component={Gallery} exact/>
                        <Route path="/about-us" component={AboutUs} exact/>
                        {/*<Route path="/reserve" component={Reserve} exact/>*/}
                        <Route path="/reserve/:id" component={ReserveForHouse} exact/>
                        <Route path="/houses" component={Houses} exact/>
                        <Route path="/payment-result" component={PaymentResult} exact/>
                        <Route path="/szep" component={BeautifulCard} exact/>
                        <Route path="/transfer" component={Transfer} exact/>
                        <Route path="/faq" component={Faq} exact/>
                        <Route component={NotFound}/>
                    </Switch>
                </Router>
            </ApartmentProvider>
        </ConfigProvider>
    </ThemeProvider>
}

export default App;
