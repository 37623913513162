import {Stack} from "@mui/material";
import {useContext} from "react";
import ReservationContext from "../../context/ReservationContext";
import {enqueueSnackbar} from "notistack";
import Calendar from "../../components/Calendar";


const SelectDate = () => {
    const {next, availability, selected, maxDate, getAvailability, featuredPeriods, setSelected} = useContext(ReservationContext)

    const isDateValid = (date) => !isNaN(date)

    const hasValidData = dateList => dateList.every(d => d) && dateList.every(d => isDateValid(d))

    const isDateRangeValid = dateList => {
        if (!Array.isArray(dateList) || dateList.length < 2) return false
        const [startDate, endDate] = dateList;
        if (!(startDate instanceof Date) || !(endDate instanceof Date)) return false
        return startDate < endDate
    }

    const doesMeetMinDaysRequirement = dateList => {
        let isOk = true
        for (const period of featuredPeriods) {
            const { start_date, end_date, min_days } = period
            const startDate = new Date(start_date)
            const endDate = new Date(end_date)
            const [selectedStartDate, selectedEndDate] = dateList
            if (isIntersects(selectedStartDate, selectedEndDate, startDate, endDate)) {
                const intersectingDaysCount1 = intersectingDaysCount(selectedStartDate, selectedEndDate, startDate, endDate);
                console.log(`intersectingDaysCount1: ${intersectingDaysCount1}, min_days: ${min_days}`)
                if (intersectingDaysCount1 < min_days) isOk = false
            }
        }
        return isOk
    }

    const isIntersects = (selectedStart, selectedEnd, start, end) => {
        selectedStart = new Date(selectedStart).setHours(0, 0, 0, 0);
        selectedEnd = new Date(selectedEnd).setHours(0, 0, 0, 0);
        start = new Date(start).setHours(0, 0, 0, 0);
        end = new Date(end).setHours(0, 0, 0, 0);
        console.log(`selectedStart: ${selectedStart}, selectedEnd: ${selectedEnd}`);
        console.log(`start: ${start}, end: ${end}`);
        // if (isNaN(selectedStart.getTime()) || isNaN(selectedEnd.getTime()) ||
        //     isNaN(start.getTime()) || isNaN(end.getTime())) {
        //     throw new Error('Invalid date format.');
        // }
        console.log(`(selectedStart < end && selectedEnd >= start): ${(selectedStart < end && selectedEnd >= start)}`)
        return (selectedStart < end && selectedEnd >= start)
    }

    const intersectingDaysCount = (selectedStart, selectedEnd, start, end) => {
        selectedStart = new Date(selectedStart).setHours(0,0,0,0);
        selectedEnd = new Date(selectedEnd).setHours(0,0,0,0);
        start = new Date(start).setHours(0,0,0,0);
        end = new Date(end).setHours(0,0,0,0);
        const latestStart = selectedStart > start ? selectedStart : start;
        console.log(`latestStart: ${latestStart}`);
        const earliestEnd = selectedEnd < end ? selectedEnd : end;
        console.log(`earliestEnd: ${earliestEnd}`);
        const oneDay = 24 * 60 * 60 * 1000;
        const daysCount = Math.round(Math.abs((earliestEnd - latestStart) / oneDay));
        console.log(`daysCount: ${daysCount}`);
        return daysCount
    }

    const minTwoDays = dateList => {
        const oneDay = 24 * 60 * 60 * 1000
        return Math.round(Math.abs((dateList[1] - dateList[0]) / oneDay)) >= 2
    }

    const validators = [
        {isValid: isDateRangeValid, message: 'Nem megfelelő dátum!'},
        {isValid: doesMeetMinDaysRequirement, message: 'Nem teljesül a minimum napok száma a kiemelt időszakban!'},
        {isValid: minTwoDays, message: 'Minimum két éjszakára kell foglalni!'},
    ]

    const handleDateChange = (newValue) => {
        if (hasValidData(newValue)) {
            // check
            for (let validator of validators) {
                if (!validator.isValid(newValue)) {
                    setSelected({...selected, selectedDate: newValue})
                    enqueueSnackbar({message: validator.message, variant: 'error'})
                    return
                }
            }
            next({selectedDate: newValue})
            return
        }
        setSelected({...selected, selectedDate: newValue})
    }

    const handleMonthChange = (newValue) => getAvailability(newValue.getFullYear(), newValue.getMonth() + 1)

    return <div className="data-container">
        <Stack spacing={{xs: 2}}>
            <Calendar
                disabledRanges={availability}
                handleSelectRange={handleDateChange}
                handleMonthChange={handleMonthChange}
                minDate={new Date()}
                maxDate={maxDate}
                disabledColor={'#e8e8e8'}
            />
        </Stack>
    </div>
}
export default SelectDate