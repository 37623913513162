import { Stack, Typography } from "@mui/material";
import { thousandSeparator } from "../utils/utils";

const PriceDisplay = ({ price }) => {
    const minPrice = Math.min(...Object.values(price));

    return (
        <Stack spacing={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1">Ár: {thousandSeparator(minPrice)} Ft / éj-től</Typography>
            </div>
        </Stack>
    );
};

export default PriceDisplay;
