import Typography from "@mui/material/Typography";
import {Button, Grid, Stack, useTheme} from "@mui/material";
import ErrorHandlingTextField, {
  validateEmail, validateMax, validateMin,
  validateNumber,
  validateRequired
} from "../../components/ErrorHandlingTextField";
import ErrorHandlingPhoneInput, {
  validatePhoneNumber,
  validatePhoneRequired
} from "../../components/ErrorHandlingPhoneInput";
import {useContext} from "react";
import ReservationContext from "../../context/ReservationContext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const SelectPersonal = () => {
  const theme = useTheme()
  const {personalData, setPersonalData, error, setError, next, selected, isError, isPersonalFilled} = useContext(ReservationContext)
  const headingStyle = {color: theme.palette.primary[500]}

  return <div className="data-container-fill">
    <form style={{marginTop: 10}}>
      <Grid container marginBottom={2} spacing={{xs: 2}}>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Typography variant='caption' sx={headingStyle}>Vezetéknév*</Typography>
            <ErrorHandlingTextField check={v => setError({...error, last_name: v})}
                                    validators={[validateRequired]} value={personalData?.last_name}
                                    variant='standard' name="last_name"
                                    onValChange={e => setPersonalData({...personalData, last_name: e.target.value})}/>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Typography variant='caption' sx={headingStyle}>Keresztnév*</Typography>
            <ErrorHandlingTextField check={v => setError({...error, first_name: v})}
                                    validators={[validateRequired]} value={personalData?.first_name}
                                    variant='standard' name="first_name"
                                    onValChange={e => setPersonalData({...personalData, first_name: e.target.value})}/>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Typography variant='caption' sx={headingStyle}>Email*</Typography>
            <ErrorHandlingTextField check={v => setError({...error, email: v})}
                                    validators={[validateRequired, validateEmail]} value={personalData?.email}
                                    variant='standard' name="email"
                                    onValChange={e => setPersonalData({...personalData, email: e.target.value})}/>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Typography variant='caption' sx={headingStyle}>Telefonszám*</Typography>
            <ErrorHandlingPhoneInput
                defaultCountry='hu'
                variant='standard'
                check={v => setError({...error, phone: v})}
                value={personalData?.phone}
                validators={[validatePhoneRequired, validatePhoneNumber]}
                onValChange={e => setPersonalData({...personalData, phone: e})}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Typography variant='caption' sx={headingStyle}>Vendégek száma*</Typography>
            <ErrorHandlingTextField check={v => setError({...error, number_of_guests: v})}
                                    validators={[validateRequired, validateNumber, v => validateMax(selected?.apartment?.capacity, v), v => validateMin(1, v)]} value={personalData?.number_of_guests}
                                    variant='standard' name="number_of_guests"
                                    onValChange={e => setPersonalData({...personalData, number_of_guests: e.target.value})}/>
          </Stack>
        </Grid>
      </Grid>
      {/*<Button disabled={!isPersonalFilled() || !isError()} onClick={() => next({...personalData})} endIcon={<NavigateNextIcon/>} variant='contained'>Tovább</Button>*/}
      <Button disabled={!(isPersonalFilled() && isError())} onClick={() => next({...personalData})} endIcon={<NavigateNextIcon/>} variant='contained'>Tovább</Button>
    </form>
  </div>
}

export default SelectPersonal