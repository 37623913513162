import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./Typography";
import {components} from "./componentDef";


// const belaWoodColors = {
//     '900': '#1a1a1a',
//     '800': '#313131',
//     '700': '#464646',
//     'A700': '#5d5d5d',
//     '600': '#777777',
//     '500': '#838383',
//     '400': '#8f8f8f',
//     'A400': '#9a9a9a',
//     '300': '#a2a2a2',
//     '200': '#ababab',
//     'A200': '#b6b6b6',
//     '100': '#bdbdbd',
//     'A100': '#cecece',
//     '50': '#dcdcdc',
//     '20': '#f1f1f1'
// }

const belaWoodColors = {
    '900': '#1c100b',
    '800': '#281710',
    '700': '#3d2319',
    'A700': '#502e21',
    '600': '#603728',
    '500': '#754736',
    '400': '#b49a87',
    'A400': '#9b6652',
    '300': '#ab745f',
    '200': '#b67d68',
    'A200': '#c48872',
    '100': '#d79982',
    'A100': '#e8ab94',
    '50': '#f3b8a2',
    '20': '#e4d4c8'
}


const belaWoodTheme = createTheme({
        components: components,
        name: 'belawood',
        palette: {
            primary: belaWoodColors,
            secondary: belaWoodColors,
        },
        typography: typography
    },
    huHU
)

export default belaWoodTheme