import {Grid, Stack, useTheme} from "@mui/material";

import {useContext} from "react";
import Typography from "@mui/material/Typography";
import ReservationContext from "../../context/ReservationContext";
import Loading from "../../components/Loading";
import SelectApartment from "./SelectApartment";
import SelectDate from "./SelectDate";
import BelawoodStepper from "../../components/Stepper";
import Selected from "./Selected";
import SelectPersonal from "./SelectPersonal";
import SelectPayment from "./SelectPayment";



const ReserveBase = () => {
    const theme = useTheme()
    const {loading, activeStep} = useContext(ReservationContext)

    const stepMap = [
        // <SelectApartment/>,
        <SelectDate/>,
        <SelectPersonal/>,
        <SelectPayment/>,
    ]

    const textMap = [
        // {heading: 'Apartman kiválasztása', text: 'Kérjük válasszon az alábbi apartmanok közül'},
        {heading: 'Dátum kiválasztása', text: 'Kérjük válasszon az alábbi naptárból'},
        {heading: 'Személyes adatok', text: 'Kérjük töltse ki az alábbi mezőket'},
        {heading: 'Számlázási és fizetési adatok', text: 'Kérjük válasszon a fizetési módok közül és adja meg a számlázási adatait'},
    ]

    return <>
        <Stack>
            <Typography variant="headline2" style={{color: theme.palette.primary[600]}} gutterBottom>{textMap[activeStep].heading}</Typography>
            <Typography variant="subtutle1" style={{color: theme.palette.primary[400], fontWeight: '700'}} gutterBottom>{textMap[activeStep].text}</Typography>
        </Stack>
        <BelawoodStepper/>

        <Grid container spacing={6}>
            <Grid item xs={12} md={7}>
                {stepMap[activeStep]}
            </Grid>
            <Grid item xs={12} md={5}>
                <Selected />
            </Grid>
        </Grid>

        <Loading isLoading={loading}/>
    </>
}

export default ReserveBase