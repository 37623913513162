import ResponsiveAppBar from "../components/AppBar";
import Footer from "../components/Footer";
import {Typography} from "@mui/material";

const Transfer = () => {
    return <>
        <ResponsiveAppBar/>
        <div className="main" style={{minHeight: '60vh'}}>
            <Typography variant='headline1'>Átutalás</Typography>
            <Typography variant='body1' marginBottom={4}>SIKERES FOGLALÁS!
                Az apartmant sikeresen lefoglaltuk számodra, viszont ne feledd, hogy 3 nap áll rendelkezésedre a foglalás véglegesítésére azáltal, hogy az összeget átutalod részünkre.
                Az utalás részleteit elküldtük emailben.
            </Typography>
        </div>
        <Footer/>
    </>
}

export default Transfer